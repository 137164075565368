import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import Image from "gatsby-image";
import styled from "styled-components";
import { capitalize } from "lodash";

import Layout from "../components/Layout";
import Timestamp from "../components/Timestamp";

import Content, { HTMLContent } from "../components/Content";
import { ResponsiveWidthContainer, SectionTitle } from "../components/Section";
import {
  SocialShareLinks,
  VerticalSocialShareLinks
} from "../components/ShareIcons";
import { Button, DownloadButton } from "../components/Buttons";

const blogPostStyle = `
  color: gray;
`;

const blogPostMetaStyle = `
  font-size: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  @media (min-width: 700px) {
    flex-flow: row nowrap;
  }  
  justify-content: space-between;
  padding: 1rem 0;
  margin-top: 1rem;
`;

const postMainSectionStyle = `
  display: flex;
  flex-flow: column nowrap;
  text-align: left;

  @media (min-width: 700px) {
    flex-flow: row nowrap;
  }

  > div:first-child {
    position: sticky;
    top: 70px;
    flex-grow: 0;
    background-color: white;
    width: 100%;
    @media (min-width: 700px) {
      width: auto;
    }
  }

  @media (min-width: 700px) {
    > div:first-child {
      min-width: 100px;
    }
  }


  > div:nth-child(2) {
    flex-grow: 1;
    margin-right: 1.5rem;
  }
`;

const SocialIconBox = styled.div`
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  color: #02bbe7;
  > div:first-child {
    margin-bottom: 1rem;
  }
`;

const ReponsiveBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-contain: space-between;

  @media all and (min-width: 720px) {
    flex-flow: row nowrap;
  }

  > *:first-child {
    flex-grow: 1;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-contain: space-between;
  > * {
    ${props => (props.center ? `margin: 0 auto;` : ``)};
    ${props => (props.left ? `margin-right: auto;` : ``)};
  }
  ${props =>
    props.hideMobile
      ? `display: none; @media (min-width: 720px) { display: flex; }`
      : ``}
`;

const StyledResponsiveWidthContainer = styled(ResponsiveWidthContainer)`
  > section {
    margin-top: 3rem;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  color: #3d3a3c;
  margin: 0;
`;

const StyledVerticalSocialShareLinks = styled(VerticalSocialShareLinks)`
  color: #02bbe7;
`;

export const SolarGuidePageTemplate = ({
  title,
  date,
  author,
  pdflink,
  buttontext,
  location,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  const postData = {
    title,
    siteTitle: "Your Solar Quotes",
    href: location.href
  };

  return (
    <StyledResponsiveWidthContainer>
      <div css={blogPostStyle}>
        <ReponsiveBox>
          <StyledSectionTitle leftAlign>{title}</StyledSectionTitle>
          <Column hideMobile>
            <SocialIconBox>
              <SocialShareLinks shareData={postData} />
              <div>Share the knowledge!</div>
            </SocialIconBox>
          </Column>
        </ReponsiveBox>
        <DownloadButton outline color="basic" fileUrl={pdflink}>
          Download PDF
        </DownloadButton>
        <div css={blogPostMetaStyle}>
          <small>
            <b>By</b> {capitalize(author)}
          </small>
          <small>
            <Timestamp date={date} />
          </small>
        </div>
        <div css={postMainSectionStyle}>
          <StyledVerticalSocialShareLinks shareData={postData} />
          <PageContent className="content" content={content} />
        </div>
        <ReponsiveBox>
          <Column
            center
            css={`
              margin: 3rem;
            `}
          >
            <Button>{buttontext}</Button>
          </Column>
        </ReponsiveBox>
      </div>
    </StyledResponsiveWidthContainer>
  );
};

SolarGuidePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  pdflink: PropTypes.string.isRequired,
  buttontext: PropTypes.string.isRequired,
  location: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const SolarGuidePage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <SolarGuidePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        author={post.frontmatter.author}
        pdflink={post.frontmatter.pdflink}
        buttontext={post.frontmatter.buttontext}
        location={location}
        content={post.html}
      />
    </Layout>
  );
};

SolarGuidePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default SolarGuidePage;

export const solarGuidePageQuery = graphql`
  query SolarGuidePage2($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date
        author
        pdflink
        buttontext
      }
    }
  }
`;
