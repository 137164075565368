import React from "react";
import { PropTypes } from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { SocialIconLink } from "./SocialIconLink";

const SocialIconBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 3rem;
  > a {
    color: currentColor;
    padding-right: 1rem;
    font-size: ${props => (props.large ? `3rem` : `inherit`)};
  }
`;

const SocialShareLinks = ({ shareData, className, ...rest }) => {
  const data = useStaticQuery(graphql`
    query SocialShareLinksQuery {
      shareLinks: allSharingYaml {
        edges {
          node {
            brand
            shareurl
          }
        }
      }
    }
  `);

  return (
    <SocialIconBlock className={className} {...rest}>
      {data.shareLinks.edges.map(({ node: { brand, shareurl } }) => {
        let d = {
          link: shareurl + shareData.href,
          brand
        };

        if (brand === "Email") {
          d.link =
            d.link + `&subject=${shareData.title} — ${shareData.siteTitle}`;
        }

        return <SocialIconLink key={d.brand} {...d} />;
      })}
    </SocialIconBlock>
  );
};

SocialShareLinks.propTypes = {
  shareData: PropTypes.object,
  className: PropTypes.string
};

const VerticalSocialShareLinks = styled(SocialShareLinks)`
  @media (min-width: 700px) {
    flex-flow: column nowrap;
  }
`;

export { SocialShareLinks, VerticalSocialShareLinks };
