import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format as formatDate } from "date-fns";
import { FiClock } from "react-icons/fi";

const StyledClock = styled(FiClock)`
  margin-right: 7px;
  margin-bottom: 1px;
`;

const Timestamp = ({ date, lastUpdatedText = true }) => {
  const formattedDate = formatDate(date, "MMMM Do, YYYY");

  return (
    <div>
      <StyledClock />
      <time dateTime={date}>
        {lastUpdatedText && `Last updated`} {`${formattedDate}`}
      </time>
    </div>
  );
};

Timestamp.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastUpdatedText: PropTypes.bool
};

export default Timestamp;
